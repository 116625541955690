const { gtmQueue } = require('./gtmQueue');

/**
 * push the event that is fired to google analytics to the datalayer
 * @param {object} params has the data to push to the datalayer
 */
const fireGAEvent = (params) => {
	const {
		event = 'analyticsEvent',
		eventCategory = 'Template Area Interaction',
		eventAction = '',
		eventLabel = '',
		eventValue = '',
	} = params;

	// Delay events until after page view or CDs won't be sent.
	if (!(window.dataLayer || [])[0]) {
		setTimeout(() => {
			fireGAEvent(params);
		}, 100);
	} else {
		gtmQueue.pushTask(() => {
			window.dataLayer.push({
				event,
				'event category': eventCategory,
				'event action': eventAction,
				'event label': eventLabel,
				...(eventValue && { 'event value': eventValue }),
			});
		});
	}
};

/**
 * Formats a tracking props recieved from several garden components /eg. Accordion in FAQ and TabItems * in Market Stats Section.
 * @param {String} event tracking event
 * @param {String} label tracking label
 * @param {String} action tracking action
 */
const nftTrackingPropsNormalizer = (category, action = 'Click', label) => fireGAEvent({
	eventCategory: category,
	eventAction: action,
	eventLabel: label,
});

const getIsNewsletterPage = () => window.location.pathname.split('/')[1] === 'newsletters';

const updateTrackingData = (newData) => {
	if (!window.forbes) window.forbes = {};
	if (!window.forbes['simple-site']) window.forbes['simple-site'] = {};

	window.forbes['simple-site'].tracking = {
		...window.forbes['simple-site'].tracking || {},
		...newData,
	};
};

const newsLetterSubscribeTracking = {
	track: (category, action, label) => {
		fireGAEvent({
			eventCategory: category,
			eventAction: action,
			eventLabel: label,
		});
	},
	category: 'Template Area Interaction',
	action: 'click',
	label: 'newsletter-subscribe-navigation',
};

/**
 * Formats a tracking label for "For You" Section based on the provided parameters.
 *
 * @param {string} prefix to be prepended.
 * @param {string} uri The URI associated with the article.
 * @param {number} index The index of the article in the list.
 * @param {string} event The user event associated with the tracking label.
 * @returns {string} The formatted tracking label.
 */
const formatForYouTrackingLabel = ({
	prefix, uri, index, event,
}) => `${prefix}${index + 1}-${event}-${uri}`;

/**
 * Formats a tracking label for "Trending" Section based on the provided parameters.
 *
 * @param {string} prefix to be prepended.
 * @param {string} naturalId id of article.
 * @param {number} index The index of the article in the list.
 * @param {string} event The user event associated with the tracking label.
 * @returns {string} The formatted tracking label.
 */
const formatTrendingTrackingLabel = ({
	prefix, naturalId, index, event,
}) => `${prefix}-${event}-${index + 1}-${naturalId}`;

/**
 * Fire a GA event when element is considered by the threshold to be in view.
 * @param {HTMLElement} element The element to track the inview.
 * @param {String} eventLabel The tracking event label.
 * @param {Number} threshold The threshold set to fire the inview tracking.
 * @param {String} eventAction The tracking event action.
 * @param {String} eventValue
 * @param {String} eventCategory
 * @returns if html element is null.
 */
const primeInviewEvent = (element, eventLabel, threshold = 1, eventAction = 'scroll', eventValue = '', eventCategory = 'Template Area Interaction') => {
	if (!element) {
		return;
	}

	const observer = new IntersectionObserver((entries) => {
		if (entries[0].isIntersecting) {
			const GAData = {
				eventAction,
				eventLabel,
				eventValue,
				eventCategory,
			};
			fireGAEvent(GAData);
			observer.unobserve(entries[0].target);
		}
	}, {
		threshold,
	});

	observer.observe(element);
};

module.exports = {
	fireGAEvent,
	getIsNewsletterPage,
	updateTrackingData,
	newsLetterSubscribeTracking,
	nftTrackingPropsNormalizer,
	formatForYouTrackingLabel,
	formatTrendingTrackingLabel,
	primeInviewEvent,
};
