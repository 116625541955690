import { fireGAEvent } from './trackingUtils';

/**
 * Push the events to the dom element to push the data to the datalayer
 * @param {DOMElement} element the dom element the contains the element
 * @param {string} eventType the event type to add the GTM tracking to
 * @param {String} category the catogry for the event details
 * @param {string} eventTypeLabel the label for the event
 */
const pushEvent = (element, eventType, category, eventTypeLabel) => {
	const eventParams = {
		eventCategory: category,
		eventAction: eventType,
		eventLabel: eventTypeLabel,
	};

	element.addEventListener(eventType, () => {
		fireGAEvent(eventParams);
	});
};

export default pushEvent;
